 

/*
  REACT
*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
  TYPOGRAPHY
  https://skylab.avalara.com/77horepq1/p/310671-typography/b/76b3c8
*/

body {
  color: var(--color-gray-darkest);
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dl-product-name {
  font-family: apertura, sans-serif;
}

/*
  COLOR PALETTE
  https://skylab.avalara.com/77horepq1/p/34a815-colors/b/54ec6b
  https://skylabsdk.avalara.io/styles/colors
*/

.dl-brand-orange {
  color: var(--color-brand-orange);
}

.dl-brand-blue {
  color: var(--color-brand-blue);
}

.dl-brand-gray {
  color: var(--color-gray-medium);
}

.card {
  height: 7.5rem;
  transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
  box-shadow: 0rem 0rem 0.2rem var(--color-gray-light);
  cursor: pointer;
}

/*
  HYPERLINKS
  https://skylab.avalara.com/77horepq1/p/330673-links/b/06e81d
*/

a {
  color: var(--color-blue-dark);
}

a:hover {
  color: var(--color-link-hover);
}

a:active {
  color: var(--color-link-pressed);
}

a:visited {
  color: var(--color-blue-dark);
}

/*
  FLEXBOX
*/

.dl-flex-fill-height {
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
}

.dl-flex-center {
  justify-content: center;
  align-items: center;
}

.dl-scroll {
  overflow-y: auto;
}

.dl-content {
  margin: 0 auto;
  min-width: 100rem;
  padding: 0 1.85rem;
}

.dl-upload-dialog-height {
  max-height: 97vh !important;
}

@media only screen and (max-width: 1600px) {
  .dl-content {
    margin: 0;
    min-width: 0;
    padding: 0 1.85rem;
  }
}

@media only screen and (max-width: 1259px) {
  .dl-content {
    margin: 0;
    min-width: 0;
    padding: 0 1.5rem;
  }
}

@media only screen and (max-width: 839px) {
  .dl-content {
    margin: 0;
    min-width: 0;
    padding: 0 0.75rem;
  }
  .card {
    height: 10rem;
    transition: box-shadow 0.3s ease-in-out;
  }
}
