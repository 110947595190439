.dl-error-background {
  background-image: url("../../assets/images/errorImage.jpg");
  background-size: cover;
  align-items: center;
}

.dl-accountExpired-background {
  background-image: url("../../assets/images/errorbackground.jpg");
  background-size: cover;
}
